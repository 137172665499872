import React, { FC } from 'react'
import { SliderComponent } from '../components'
import SliderData from './data/slider.json'

const SliderContainer: FC = () => (
  <SliderComponent
    title={SliderData.title}
    image={SliderData.image}
    subtitle={SliderData.subtitle}
    boxes={SliderData.boxes}
  />
)

export default SliderContainer
